import { just, Maybe, nothing } from 'maybeasy';
import {action, computed, decorate, observable} from 'mobx';
import {Alert} from '../models/alert';

class AlertService {
    alerts: Alert[] = [];

    get current(): Maybe<Alert> {
        return this.alerts.length === 0 ? nothing() : just(this.alerts[0]);
    }
    
    hide = () => {
        if (this.alerts.length > 0) {
            this.alerts[0].display = false;
        }
    };

    process = () => {
        this.alerts = this.alerts.slice(1);
    };
    
    push = (alert: Alert) => {
        this.hide();
        this.alerts.push({ ...alert, display: true });
    };
    
    clear = (source: string) => {
        let cleared = this.alerts.filter((item: Alert)=> {
            return item.source != source;     
        });
        this.alerts = cleared;
    }
}

decorate(AlertService, {
    alerts: observable,
    current: computed,
    hide: action,
    process: action,
    push: action
})

const alertService = new AlertService();
export default alertService;