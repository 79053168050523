import {action, computed, decorate, flow, observable, runInAction} from 'mobx';
import { ActivationCode } from '../models/activation-code';
import axios from "axios";
import alertService from "./alert";
import {Alert, AlertType} from "../models/alert";
import {User} from "../models/user";
import {navigate} from "gatsby";
import * as messages from "../components/ui_messages";
import {AppAuth} from "../models/auth";

class UserService {
    activationCodes: ActivationCode[] = [];
    currentUser: User | undefined;
    appAuth: AppAuth | undefined;
    firstLoad: boolean = true;

    constructor() {
        if (!this.isBrowser()) return;

        const json = window.localStorage.getItem("s7n_user");
        if (json == null)
            return;

        this.setCurrentUser(JSON.parse(json));

        const app_auth_json = window.localStorage.getItem("s7n_app_auth");
        if (app_auth_json == null)
            return;
        this.setAppAuth(JSON.parse(app_auth_json));
    }

    private isBrowser = () => typeof window !== "undefined"

    isLoggedIn = () => {
        return this.currentUser != undefined;
    }

    addActivationCode = async (code: string) => {

        alertService.clear('activation');

            if (this.currentUser == undefined)
            {
                alertService.push(new Alert("You must login first before adding a new activation code.", 'activation', AlertType.Error))
                return;
            }

        let self = this;

        let data = JSON.stringify({
            "code": code,
            "user_id": this.currentUser.id,
        })

        axios.post(process.env.API_URL + '/activation_codes', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async function (response) {
            self.push(response.data);
            alertService.push(new Alert("Successfully registered new activation code.", 'activation', AlertType.Success));
            return true;
        }).catch(function (error) {
            alertService.clear('activation');
            let msg = (error.response) ? error.response.data.exception : "Invalid activation code";
                msg += ". Please re-enter the code and try again.";
                alertService.push(new Alert(msg, 'activation', AlertType.Error))
            return false;
        });

        return true;
    }

    forgotPassword = (email: string) => {

        const data = JSON.stringify({
            email: email,
            redirect_url: process.env.GATSBY_ROOT_URL + "/reset-password/"
        });

        alertService.clear('forgotPassword');

        axios.post(process.env.API_URL + '/auth/password', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            alertService.push(new Alert("If you have an account, check your inbox for directions on how to reset your password.",
                'forgotPassword', AlertType.Success));

        }).catch(function (error) {
            alertService.clear('forgotPassword');
            let serverError = "";
            console.log(`error in forgotPassword: ${error.response}`);

            if (error.response.status == 404)
            {
                serverError = "If you have an account, check your inbox for directions on how to reset your password."
            }
            else
            {
                serverError = messages.REGISTER_GENERIC_ERROR;
            }

            alertService.push(new Alert(`${serverError}`, 'forgotPassword', AlertType.Error));
            return false;
        });

        return true;
    }


    resetPassword = (password: string, client_id, expiry, token, uid) => {

        const data = JSON.stringify({
            password: password,
            password_confirmation: password,
        });

        alertService.clear('forgotPassword');

        axios.put(process.env.API_URL + '/auth/password', data, {
            headers: {
                'Content-Type': 'application/json',
                'access-token': token,
                'client': client_id,
                'expiry': expiry,
                'uid':uid,
                'token-type':"Bearer"
            }
        }).then(function (response) {

            alertService.push(new Alert("You're password has been updated",
                'resetPassword', AlertType.Success));

        }).catch(function (error) {
            alertService.clear('resetPassword');
            let serverError = "";
            console.log("error",error.response);

            if(error.response && error.response.data.errors.full_messages){
                serverError=error.response.data.errors.full_messages[0]
            }
            else if (error.response.data.errors) {
                serverError=error.response.data.errors[0]
            }
            else {
                serverError = messages.REGISTER_GENERIC_ERROR
            }

            alertService.push(new Alert(`${serverError}`, 'forgotPassword', AlertType.Error));
            return false;
        });

        return true;
    }

    register = (email: string, password: string, passwordConfirmation: string, activationCode: string, confirmSuccessURL: string, configName: string) => {

        let self = this;

        let data = JSON.stringify({
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            activationCode: activationCode,
            confirm_success_url: confirmSuccessURL,
            config_name: configName
        })

        axios.post(process.env.API_URL + 'auth', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async function (response) {
            const user = response.data.data;
            //self.setCurrentUser(user);
            //TODO - fix bug in api that doesn't report the correct app_type after registering. Force user to login is a work around
            alertService.clear('registration');
            alertService.hide();
            alertService.push(new Alert("Thanks for registering. Please login.", 'log-in', AlertType.Success));

            await navigate(`/login/`);
        }).catch(function (error) {
            alertService.clear('registration');
            let serverError = "";
            console.log(`error in registration: ${error.response}`);

            if (error.response.status == 422)
            {
                serverError = messages.REGISTER_EMAIL_ALREADY_REGISTERED;
            }
            else if (error.response.status == 400)
            {
                serverError = messages.REGISTER_INVALID_ACTIVATION_CODE;
            }
            else
            {
                serverError = messages.REGISTER_GENERIC_ERROR;
            }

            alertService.push(new Alert(`${serverError}`, 'registration', AlertType.Error));
            return false;
        });

        return true;
    }

    login = (username: string, password: string ) => {
        console.log(`handleLogin ${username}/${password} -> ${process.env.API_URL}`);
        let self = this;
        axios.post(process.env.API_URL + 'auth/sign_in', {
            "email": username,
            "password": password,
        }).then(async function (response) {
            let user = response.data.data;
            const appAuth = response.headers;
            console.log(appAuth);
            console.log(user);

            self.setCurrentUser(user);
            self.setAppAuth(appAuth);
            let usersFirstPage = "/main";

            if(!user.is_active){
                alert("That's the end of the preview. Please add an activation code");
                alertService.clear('log-in');
                alertService.clear('registration');
                alertService.hide();
                alertService.push(new Alert("Please add an activation code to continue using the app.", 'preview', AlertType.Error));

                usersFirstPage = '/license/';
            }

            //if(user.app_type == "your_move") usersFirstPage = "/lesson-codes";

            await navigate(usersFirstPage)
        }).catch(function (error) {
            console.log(`error in login: ${error}`);
            alertService.push(new Alert("Error during log-in.", 'log-in', AlertType.Error));
            return false;
        });

        return true;
    }

    logout = callback => {
        this.currentUser = undefined;
        this.activationCodes = [];

        if (!this.isBrowser()) return;
        window.localStorage.removeItem("s7n_user");

        callback();
    }

    push = (activation: ActivationCode) => {
        if (this.currentUser == undefined) return;

        console.log("adding new activation code...");
        console.log(activation);

        // update the activations, then run thru storage routine...
        this.currentUser.activation_codes.push(activation);
        this.setCurrentUser(this.currentUser);
    };

    is_active(user: User): boolean {
        let active_codes = user.activation_codes.filter(code => !code.expired);
        return active_codes.length > 0;
    }
    setCurrentUser = (user: User) => {
        user.is_active = this.is_active(user);

        if(this.isBrowser()) {
            const up = window.localStorage.getItem("user_position");
            if(up) {
                const pos = JSON.parse(up);
                user.upcomingClose = pos.upcomingClose;
            }
            window.localStorage.setItem("s7n_user", JSON.stringify(user));

        }

        if (user.activation_codes?.length > 0)
            this.activationCodes = user.activation_codes;

        this.currentUser = this.maybeSetAsAdmin(user);

        return true;
    }

    maybeSetAsAdmin = (user: User): User => {
        const adminUsers = [
            "mandy@cmu.edu",
            "downs@cmu.edu",
            "Pamela.Murray@childrens.harvard.edu",
            "janet.smith.pgh@gmail.com",
            "seventeendays@andrew.cmu.edu",
            "andrewboehm+seventeendays@openarc.net",
            "andrewboehm+ym@openarc.net",
        ]

        if (adminUsers.includes(user.email)) {
            user.is_admin = true;
        }

        return user;
    }

    /*
    This method sets the authentication headers needed for 17days endpoints
     */
    setAppAuth = (appAuth: AppAuth) => {

        this.appAuth = appAuth;
        if(this.isBrowser())
            window.localStorage.setItem("s7n_app_auth", JSON.stringify(appAuth));

        return true;
    }

    updatePosition = data => {
        const self = this;
        if(data.position && data.current_key) {
            //console.log("updatePosition", data);
            let jsonStringData = JSON.stringify(data)
            data.upcomingClose = userService.currentUser?.upcomingClose;
            window.localStorage.setItem("user_position", JSON.stringify(data));

            axios.put(process.env.API_URL + 'auth', jsonStringData, {
                headers:  userService.appAuth
            }).then(async function (response) {
                const r = response;
                //console.log(r);
                let newAppAuth = {...userService.appAuth, ...response.headers};
                userService.setAppAuth(newAppAuth);
                const newUserData = {...self.currentUser, ...data};

                self.setCurrentUser(newUserData);
            }).catch(function (error) {
                alertService.clear('tracking');
                let serverError = "";
                //console.log(`error in updatePosition:`, error.response);

                serverError = messages.REGISTER_GENERIC_ERROR;

                alertService.push(new Alert(`${serverError}`, 'tracking', AlertType.Error));
                return false;
            });

        }

        return true;
    }

    clearPosition = () => {
        const self = this;
            const data = {
                position: null,
                current_file: null,
                current_file_time: null,
                current_key: null,
                jessicas_room: false,
            }
            console.log("clearPosition", data);
            let jsonStringData = JSON.stringify(data)

            axios.put(process.env.API_URL + 'auth', jsonStringData, {
                headers:  userService.appAuth
            }).then(async function (response) {
                const r = response;
                console.log(r);
                let newAppAuth = {...userService.appAuth, ...response.headers};
                userService.setAppAuth(newAppAuth);
                const newUserData = {...self.currentUser, ...data};

                self.setCurrentUser(newUserData);
            }).catch(function (error) {
                alertService.clear('tracking');
                let serverError = "";
                console.log(`error in updatePosition:`, error.response);

                serverError = messages.REGISTER_GENERIC_ERROR;

                alertService.push(new Alert(`${serverError}`, 'tracking', AlertType.Error));
                return false;
            });



        return true;
    }


    updateVideoProgress = (data) => {
        const self = this;
        if (data.current_file && data.current_file_time && data.current_key) {
            //console.log("updateVideoProgress", data);
            let jsonStringData = JSON.stringify(data)

            axios.put(process.env.API_URL + 'auth', jsonStringData, {
                headers:  userService.appAuth
            }).then(async function (response) {
                const r = response;
                //console.log(r);
                let newAppAuth = {...userService.appAuth, ...response.headers};
                userService.setAppAuth(newAppAuth);
                const newUserData = {...self.currentUser, ...data};

                self.setCurrentUser(newUserData);
            }).catch(function (error) {
                alertService.clear('tracking');
                let serverError = "";
                console.log(`error in updateVideoProgress:`, error.response);

                serverError = messages.REGISTER_GENERIC_ERROR;

                alertService.push(new Alert(`${serverError}`, 'tracking', AlertType.Error));
                return false;
            });

        }

        return true;
    }


    getStartTime() {
        let startTime = 0;
        if (this.firstLoad && typeof this.currentUser != "undefined" && Number.isInteger(this.currentUser.current_file_time) ) {
            startTime = this.currentUser.current_file_time;
        }
        return startTime;
    }
}

decorate(UserService, {
    activationCodes: observable,
    currentUser: observable,
    appAuth: observable,
    addActivationCode: action,
    login: action,
    logout: action,
    push: action,
    setCurrentUser: action,
})

const userService = new UserService();
export default userService;