export enum AlertType {
    Error = "danger",
    Success = "success"
}

export class Alert
{
    message: string;
    source: string;
    kind: AlertType;
    display: boolean;

    constructor(message: string, source: string, kind: AlertType) {
        this.message = message;
        this.source = source;
        this.kind = kind;
        this.display = true;
    }
}